import React, { useState, useEffect } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

function Sphere(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { Hovered, onCountryPointerOver, onCountryPointerOut } = props;
  const gltf = useLoader(GLTFLoader, '/public/world-reduced-size.glb');
  const countryScale = '0.009999999776482582';
  const [countryHovered, setCountryHovered] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState(true);
  const [myScale, setMyScale] = useState(window.innerWidth < 690 ? [2, 2, 2] : [2, 2, 2]);

  useEffect(() => {
    gltf.nodes.France.material.color.set(0xffffff);
    gltf.nodes.France.material.emissive.set(0xffffff);

    gltf.nodes.France.scale.x = countryScale;
    gltf.nodes.France.scale.y = countryScale;
    gltf.nodes.France.scale.z = countryScale;

    if (defaultCountry) {
      gltf.nodes.France.material = handleColor(gltf.nodes.France.material);
    }

    gltf.scene.children[0].scale.x = 0.25;
    gltf.scene.children[0].scale.y = 0.25;
    gltf.scene.children[0].scale.z = 0.25;
  });

  useFrame(() => {

    gltf.scene.rotation.y += 0.001

    if (windowWidth != window.innerWidth) {
      setWindowWidth(window.innerWidth);
    }

    if (props.hovered !== countryHovered) {
      gltf.scene.children[1].children.map((child) => {
        if (child.name === props.hovered) {
          child.material = handleColor(child.material)
        }

        if (child.name === countryHovered) {
          child.material = backColor(child.material)
        }
      })

      setCountryHovered(props.hovered);
    }

  });

  return (
    <>
      <primitive scale={myScale} onPointerOut={(e) => {

      if (e.object.name === 'Earth') {
        gltf.nodes.Earth.material = backColorEarth(e.object.material)
        return;
      }
        e.object.material = backColor(e.object.material)
      }} onPointerOver={(e) => {

        if (e.object.name !== 'France') {
          if (defaultCountry) {
            gltf.nodes.France.material = backColor(gltf.nodes.France.material)
          }
          setDefaultCountry(false)
        }

        onCountryPointerOver(e.object.name);
        e.object.material = handleColor(e.object.material)
        }} object={gltf.scene} />
    </>
  );
}

function handleColor(material) {
  var clone = material.clone()
  clone.color.set(0x2ccce4);
  clone.emissive.set(0x000000);
  return clone;
}

function backColor(material) {
  var clone = material.clone()
  clone.color.set(0xffffff);
  clone.emissive.set(0xffffff);
  return clone;
}

function backColorEarth(material) {
  var clone = material.clone()
  clone.color.set(0x31154a);
  clone.emissive.set(0x000000);
  return clone;
}

export default Sphere;