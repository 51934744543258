import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import './Header.css';

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const githubLogo = './public/github-mark-white.png';
  const github = 'https://github.com/martinbaud';
  const skyline = 'https://skyline.github.com/martinbaud/2022';

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className="header">
      <div className="header-left">
      <a href="." className="header-text">@martinbaud</a>
        <a href={github} className="header-github"><img src={githubLogo} alt="Github Logo" />Github Profile</a>
        <a href={skyline} className="header-skyline">Skyline 2022</a>
      </div>
      <div className="header-right">
        <a className="header-button wip">PROJECTS</a>
        <a className="header-button wip">CARREER</a>
        <a className="header-button wip">TOOLS</a>
        <a className="header-button wip">CONTACT</a>
      </div>
      <div className="header-hamburger" onClick={toggleMenu}>
        <FaBars />
      </div>
      {showMenu && (
        <div className="header-menu">
          <a href={github} className="header-menu-button ">Github Profile</a>
          <a href={skyline} className="header-menu-button ">Skyline 2022</a>
          <a href="." className="header-menu-button wip">PROJECTS</a>
          <a href="." className="header-menu-button wip">CARREER</a>
          <a href="." className="header-menu-button wip">TOOLS</a>
          <a href="." className="header-menu-button wip">CONTACT</a>
        </div>
      )}
    </header>
  );
}

export default Header;