import { Canvas } from "react-three-fiber";
import { useEffect, useState, Suspense } from 'react'
import { GoGitPullRequest } from 'react-icons/go';
import { GoRepo } from 'react-icons/go';
import { GoOrganization } from 'react-icons/go';
import { GiEarthAmerica } from 'react-icons/gi';
import { SiWikipedia } from 'react-icons/si';
import styled from 'styled-components';
import logo from './logo.svg';
import './App.css';
import './Data.css';
import Header from './Header'
import Sphere from './Sphere';
import TypingText from './TypingText';
import countries from './countries.json';

const BlurGoGitPullRequest = styled(GoGitPullRequest)`
filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.8));
`;

const BlurGoRepo = styled(GoRepo)`
filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.8));
`;

const BlurGoOrganization = styled(GoOrganization)`
filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.8));
`;

const BlurGiEarthAmerica = styled(GiEarthAmerica)`
filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.8));
`;

function NumberCounter({ limit, speed }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count => count + 1);
    }, speed);

    if (count >= limit) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [count, limit]);

  return (
    <span className="ctr-n">
      {count}
    </span>
  );
}

function App() {
  const [hoveredCountry, setHoveredCountry] = useState('France');

  const handleCountryPointerOver = (name) => {
    setHoveredCountry(name);
  };

  const handleCountryPointerOut = () => {
    setHoveredCountry(null);
  };

  //const wizards = './office/wizards.png';
  //const wemanity = './office/wemanity.png';
  //const lemonde = './office/lemonde.png';
  //const penelope = './office/penelope.png';

  return (
    <div className="App">
      <Header />
      <div className="flex-container">
        <div className="flex-container-2 flex-item">
          <div className="flex-item-2">
            <ul className="ctr-ul">
              <li className="zoomIn"><BlurGoGitPullRequest className="ctr ctr-icon" /><NumberCounter limit={136} className="ctr ctr-n" speed={10} /><span className="ctr ctr-val">Pull Requests</span><span className="ctr ctr-label">(created)</span></li>
              <li className="zoomIn"><BlurGoRepo className="ctr ctr-icon" /><NumberCounter limit={6} speed={100} className="ctr ctr-n" /><span className="ctr ctr-val">Repositories</span><span className="ctr ctr-label">(working on)</span></li>
              <li className="zoomIn"><BlurGiEarthAmerica className="ctr ctr-icon" /><NumberCounter limit={5} speed={100} className="ctr ctr-n" /><span className="ctr ctr-val">Countries</span><span className="ctr ctr-label">(visited)</span></li>
              <li className="zoomIn"><BlurGoOrganization className="ctr ctr-icon" /><NumberCounter limit={4} speed={100} className="ctr ctr-n" /><span className="ctr ctr-val">Organizations</span><span className="ctr ctr-label">(working with)</span></li>
              <li></li>
            </ul>
          </div>
          <div className="flex-container-3">
            <div className="flex-item-3 flex-canvas">
            <Canvas>
              <Suspense fallback={null}>
                <pointLight position={[10, 10, 10]} />
                <pointLight position={[-10, -10, -10]} />
                <pointLight position={[-10, 10, -10]} />
                <pointLight position={[10, -10, -10]} />
                <pointLight position={[10, -10, 10]} />
                <Sphere position={[100, 100, 100]} />
                </Suspense>
                <Sphere hovered={hoveredCountry} onCountryPointerOver={handleCountryPointerOver} onCountryPointerOut={handleCountryPointerOut} />
              </Canvas>
            </div>
            <div className="flex-item-3 st-container">
              <div className="st-container-2">
            <select className="st-select" value={hoveredCountry} onChange={(event) => {
          setHoveredCountry(event.target.value)
          handleCountryPointerOver(event.target.value)
          }}>
          {countries.map((country) => {
              if (country.name === 'France') {
                return (
                  <option value="France" selected>France</option>
                )
              } else {
                return (
                  <option value={country.name === 'Zaire' ? 'Democratic Republic of the Congo' : country.name}>{country.name === 'Zaire' ? 'Democratic Republic of the Congo' : country.name}</option>
                )
              }
          })}
        </select>
        <hr></hr>
          <div>
            {countries.map((country) => {
              if (country.name === hoveredCountry) {
                return (
                  <div className="st-container-3" key={country.name === 'Zaire' ? 'Democratic Republic of the Congo' : country.name}>
                    <a href="." className="st-init">
                      <span className="st-flag"><img src={country.flag} /></span>
                      </a>
                    <span className="st-data-ct"><span className="st-label">Population:</span> <span className="st-data">{country.population} </span> ppl</span>
                    <span className="st-data-ct"><span className="st-label">Area:</span> <span className="st-data">{country.area}</span> km²</span>
                    <span className="st-data-ct"><span className="st-label">Language:</span> <span className="st-data">{country.capital}</span></span>
                    <span className="st-data-ct"><span className="st-label">Currency:</span> <span className="st-data">{country.currency}</span></span>
                    <span className="st-data-ct"><span className="st-label">Continent:</span> <span className="st-data">{country.continent}</span></span>
                    <TypingText description={country.description}></TypingText>
                  </div>
                );
              }
            })}
          </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
