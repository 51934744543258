import React, { useState, useEffect } from 'react';

function TypingText(props) {
  const { description } = props;
  const [text, setText] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (text !== props.description) {
        const newText = props.description.substring(0, text.length + 1);
        setText(newText);
      } else {
        clearInterval(intervalId);
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, [text, props.description]);

  return (
    <div>
      <p>{text}</p>
    </div>
  );
}

export default TypingText;